/* Mixins */
.asset-batch-add-component .transform-column, .asset-batch-add-component .transform-column-middle {
  height: 45vh !important;
}
.asset-batch-add-component .left-transform-column .ul-list {
  max-height: calc(45vh - 48px) !important;
  height: calc(100% - 48px) !important;
}
.asset-batch-add-component .page-table-search__input {
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
}